import { WalletContextState } from '@solana/wallet-adapter-react';
import { Connection } from '@solana/web3.js';
import { VersionedTransaction } from '@solana/web3.js';
import { PublicKey, TransactionMessage } from '@solana/web3.js';

export const signSolanaMessage = async ({
  connection,
  isSigningTransaction,
  messageToSign,
  walletContextState,
}: {
  connection: Connection;
  isSigningTransaction: boolean;
  messageToSign: string;
  walletContextState: {
    publicKey: WalletContextState['publicKey'];
    wallet: WalletContextState['wallet'];
    signMessage: WalletContextState['signMessage'];
    signTransaction: WalletContextState['signTransaction'];
  };
}) => {
  const { publicKey, wallet, signMessage, signTransaction } =
    walletContextState;
  const isSquadsX = wallet?.adapter?.name === 'SquadsX';
  const isSignedTransaction = isSquadsX || isSigningTransaction || !signMessage;

  if (!signTransaction || !publicKey) {
    throw new Error('Please connect a Solana wallet first');
  }

  if (!isSignedTransaction) {
    const signedMessageBase64 = Buffer.from(
      await signMessage(new TextEncoder().encode(messageToSign)),
    ).toString('base64');

    return {
      signedMessageBase64,
      isSquadsX,
      isSignedTransaction,
    };
  }

  const messageV0 = new TransactionMessage({
    payerKey: new PublicKey(0),
    instructions: [
      {
        keys: [{ pubkey: publicKey, isSigner: true, isWritable: false }],
        data: Buffer.from(messageToSign, 'utf-8'),
        programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
      },
    ],
    recentBlockhash: (await connection.getLatestBlockhash()).blockhash,
  }).compileToV0Message();
  const tx = new VersionedTransaction(messageV0);

  const signedTransaction = await signTransaction(tx);
  const signedMessageBase64 = Buffer.from(
    signedTransaction.serialize(),
  ).toString('base64');

  return {
    signedMessageBase64,
    isSquadsX,
    isSignedTransaction,
  };
};
