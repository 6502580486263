import { Network } from '@spherelabs/common';
import axios from 'axios';

import { SphereApiEndpoints } from '@/common/hooks/useSphereApi';

export const fetchAuthMessageParams = (address: string, network: Network) =>
  axios
    .get<{
      data: {
        authMessage: {
          nonce: string;
          issuedAt: string;
        };
      };
    }>(`/v1/auth/wallet/message/${address}?network=${network}`)
    .then((response) => response.data.data.authMessage);

export const fetchCustomToken = (
  publicKey: string,
  signedMessageBase64: string,
  authMessageIssuedAt: string,
  nonce: string,
  network: Network,
  isTransaction?: boolean,
  isSquadsX?: boolean,
) =>
  axios
    .post<{ data: { firebaseCustomToken: string } }>(
      '/v1/auth/wallet/login' satisfies SphereApiEndpoints,
      {
        address: publicKey,
        signedMessage: signedMessageBase64,
        issuedAt: authMessageIssuedAt,
        nonce,
        network,
        isTransaction,
        isSquadsX,
      },
    )
    .then((response) => response.data.data.firebaseCustomToken);
