import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Network, getSolanaMessageToSign } from '@spherelabs/common';
import { showToast } from '@spherelabs/react-private/components/tailwind/AppToast';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useCallback, useEffect, useRef } from 'react';
import { toast } from 'sonner';
import { useFirebaseApp } from 'reactfire';

import { fetchAuthMessageParams, fetchCustomToken } from '../utils/signIn';
import { showApprovalToast } from '../utils/signInToasts';
import { useWalletModal } from '@tiplink/wallet-adapter-react-ui';
import { useConnectSolanaWallet } from '@spherelabs/async-wallet/useConnectSolanaWallet';
import { signSolanaMessage } from '@spherelabs/async-wallet/signSolanaMessage';
import { useAuthQueryParams } from './useAuthQueryParams';

export const useSignInWithSolana = ({
  setLoading,
}: {
  setLoading: (isLoading: boolean) => void;
}) => {
  const app = useFirebaseApp();
  const { connection } = useConnection();
  const {
    visible,
    setVisible,
    setShouldShowLedgerCheckbox,
    setIsConnectingViaLedger,
    isConnectingViaLedger,
  } = useWalletModal();
  const connectSolanaWallet = useConnectSolanaWallet({
    isWalletPickerOpen: visible,
    setWalletPickerOpen: setVisible,
    setShouldShowLedgerCheckbox,
  });
  const isConnectingViaLedgerRef = useRef(isConnectingViaLedger);
  useEffect(
    function updateIsConnectingViaLedgerRef() {
      isConnectingViaLedgerRef.current = isConnectingViaLedger;
    },
    [isConnectingViaLedger],
  );
  const { disconnect } = useWallet();
  const toastId = useRef<number | string | null>(null);
  const [, setParams] = useAuthQueryParams();

  const signInWithSolana = useCallback(async () => {
    toastId.current = showApprovalToast();
    try {
      const walletContextState = await connectSolanaWallet();
      toast.dismiss(toastId.current);
      toastId.current = null;
      const { publicKey, wallet, signMessage } = walletContextState;
      if (!publicKey) {
        return;
      }

      if (setLoading) {
        setLoading(true);
      }

      const isSquadsX = wallet?.adapter?.name === 'SquadsX';
      const isSignedTransaction =
        isSquadsX || isConnectingViaLedgerRef.current || !signMessage;
      toastId.current = showApprovalToast(isSignedTransaction);

      const authMessageParams = await fetchAuthMessageParams(
        publicKey.toBase58(),
        Network.SOL,
      );

      const messageToSign = getSolanaMessageToSign({
        issuedAt: authMessageParams.issuedAt,
        nonce: authMessageParams.nonce,
        solanaPubKey: publicKey.toBase58(),
      });

      const { signedMessageBase64 } = await signSolanaMessage({
        connection,
        isSigningTransaction: Boolean(isConnectingViaLedgerRef.current),
        messageToSign,
        walletContextState,
      });

      toast.dismiss(toastId.current);
      toastId.current = null;

      // custom token from admin dashboard
      // used to view dashboard in the POV of another application
      const customTokenFromSession = sessionStorage.getItem('customToken');
      const customToken =
        customTokenFromSession ??
        (await fetchCustomToken(
          publicKey.toBase58(),
          signedMessageBase64,
          authMessageParams.issuedAt,
          authMessageParams.nonce,
          Network.SOL,
          isSignedTransaction,
          isSquadsX,
        ));

      const auth = getAuth(app);
      await signInWithCustomToken(auth, customToken);
      showToast({ text: 'Login success!', mode: 'success' });
      setParams({ walletAddress: publicKey.toBase58() });
    } catch (e: any) {
      if (e instanceof Error) {
        showToast({ text: e.message, mode: 'error' });
      }
    } finally {
      setIsConnectingViaLedger(false);
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }

      await disconnect();
      if (setLoading) {
        setLoading(false);
      }
    }
  }, [
    app,
    connectSolanaWallet,
    connection,
    setIsConnectingViaLedger,
    disconnect,
    setLoading,
    setParams,
  ]);

  useEffect(function closeToastOnUnmount() {
    return () => {
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
        toastId.current = null;
      }
    };
  }, []);

  return signInWithSolana;
};
