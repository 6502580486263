import { getEvmMessageToSign, Network } from '@spherelabs/common';
import { getWagmiChainFromNetwork } from '@spherelabs/react-private';
import { showToast } from '@spherelabs/react-private/components/tailwind/AppToast';
import { useConfig, useDisconnect } from '@spherelabs/react-private/wagmi';
import { signMessage } from '@spherelabs/react-private/wagmi/core';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { useCallback, useEffect, useRef } from 'react';
import { useFirebaseApp } from 'reactfire';

import { fetchAuthMessageParams, fetchCustomToken } from '../utils/signIn';
import { showApprovalToast } from '../utils/signInToasts';
import { useConnectEvmWallet } from '@spherelabs/async-wallet/useConnectEvmWallet';
import { toast } from 'sonner';
import { useAuthQueryParams } from './useAuthQueryParams';

export const useSignInWithEvm = ({
  setLoading,
}: {
  setLoading: (isLoading: boolean) => void;
}) => {
  const connectEvmWallet = useConnectEvmWallet();
  const config = useConfig();
  const app = useFirebaseApp();
  const toastId = useRef<number | string | null>(null);
  const { disconnect } = useDisconnect();
  const [, setParams] = useAuthQueryParams();

  const signInWithEvm = useCallback(
    async (network: Network) => {
      setLoading(true);
      toastId.current = showApprovalToast();

      try {
        const chain = getWagmiChainFromNetwork(network);
        const account = await connectEvmWallet(chain.id);
        if (!account) {
          return;
        }

        const { address, connector } = account;
        if (!address || !connector) {
          return;
        }

        const authMessageParams = await fetchAuthMessageParams(
          address,
          network,
        );
        const messageToSign = getEvmMessageToSign({
          issuedAt: authMessageParams.issuedAt,
          nonce: authMessageParams.nonce,
          address,
          chainId: chain.id,
          chainName: chain.name,
        });

        const signedMessage = await signMessage(config, {
          message: messageToSign,
          account: address,
          connector,
        });

        toast.dismiss(toastId.current);
        toastId.current = null;

        const customToken = await fetchCustomToken(
          address,
          signedMessage,
          authMessageParams.issuedAt,
          authMessageParams.nonce,
          network,
        );

        const auth = getAuth(app);
        await signInWithCustomToken(auth, customToken);
        showToast({ text: 'Login success!', mode: 'success' });
        setParams({ walletAddress: address });
      } catch (e: any) {
        if (e.shortMessage) {
          showToast({ text: e.shortMessage, mode: 'error' });
        }
        setLoading(false);
      } finally {
        if (toastId.current !== null) {
          toast.dismiss(toastId.current);
        }
        setLoading(false);
        disconnect();
      }
    },
    [app, config, connectEvmWallet, disconnect, setLoading, setParams],
  );

  useEffect(function closeToastOnUnmount() {
    return () => {
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
        toastId.current = null;
      }
    };
  }, []);

  return signInWithEvm;
};
