import axios from 'axios';

import { SphereApiEndpoints } from '@/common/hooks/useSphereApi';

export const loginWithProvider = (firebaseIdToken: string) => {
  return axios
    .post('/v1/auth/provider/login' satisfies SphereApiEndpoints, {
      firebaseIdToken,
    })
    .then((response) => response.data.data);
};
