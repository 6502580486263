import * as yup from 'yup';

declare module 'yup' {
  interface ObjectSchema<TIn, TContext, TDefault, TFlags> {
    atLeastOne(): this;
  }
}

yup.addMethod(yup.object, 'atLeastOne', function () {
  return this.test({
    name: 'atLeastOne',
    message: '${path} must have at least one of these keys: ${keys}',
    exclusive: true,
    params: { keys: Object.keys(this.fields).join(', ') },
    test: (value) =>
      value == null || Object.values(value).some((value) => value != null),
  });
});

export { yup as default };
