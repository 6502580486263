import './SphereInputError.css';

import { clsx } from '../../../utils/clsx';
import { showToast } from '../../tailwind/AppToast';
import { Typography, TypographyProps } from '../Typography';

export const SphereInputError = ({ className, ...rest }: TypographyProps) => (
  <Typography
    variant='sm'
    as='div'
    className={clsx('sphere-input-error', className)}
    {...rest}
  />
);
