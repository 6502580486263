import { SphereSpinner } from '@spherelabs/react-private/components/css/SphereSpinner';
import React, { Fragment } from 'react';

type Logo = {
  id: string;
  component: React.ReactNode;
};

type AuthButtonProps = {
  primaryText: string;
  isLoading?: boolean;
  secondaryText?: string;
  logos: Logo[];
  onClick: () => void;
};

export const AuthButton = ({
  primaryText,
  secondaryText,
  logos,
  isLoading,
  onClick,
}: AuthButtonProps) => {
  return (
    <button
      onClick={onClick}
      type='button'
      className='flex items-center justify-between px-4 py-2 bg-white border border-gray-[#D6D9DF] rounded-md shadow-sm hover:bg-[#f5f7f9] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
    >
      {isLoading ? (
        <div className='flex flex-1 justify-center'>
          <SphereSpinner />
        </div>
      ) : (
        <Fragment>
          <div>
            <span className='font-medium text-sm text-[#7387A4]'>
              {primaryText}
            </span>

            {secondaryText ? (
              <span className='ml-1 text-sm text-[#98A2B3]'>
                {secondaryText}
              </span>
            ) : null}
          </div>

          <div className='flex items-center gap-1'>
            {logos.map((logo) => (
              <React.Fragment key={logo.id}>{logo.component}</React.Fragment>
            ))}
          </div>
        </Fragment>
      )}
    </button>
  );
};
