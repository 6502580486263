'use client';

import Image from 'next/image';
import Link from 'next/link';
import SphereLogo from 'public/landing-page/sphere-logo.svg?url';
import { useTranslation } from 'react-i18next';

export function AuthHeader() {
  const { i18n } = useTranslation();

  return (
    <header className='navbar flex min-h-[60px] fixed top-0 left-0 right-0 z-10 backdrop-blur-2xl transition-transform duration-300 ease-in-out bg-white border-b border-[#e4ebff]'>
      <div className='flex px-4 md:px-8 w-full max-w-screen-xl flex-row items-center gap-x-12 md:py-4 h-[3.6875rem] md:h-[4.3125rem]'>
        <Link href={`/${i18n.language}`} className='shrink-0'>
          <p className='sr-only'>Sphere</p>
          <Image
            src={SphereLogo}
            alt='Sphere Logo'
            className='h-auto w-[5.375rem] hover:cursor-pointer md:w-[7.5625rem] lg:mr-2'
          />
        </Link>
      </div>
    </header>
  );
}
