import { WalletContextState, useWallet } from '@solana/wallet-adapter-react';
import { useCallback, useEffect } from 'react';
import { create } from 'zustand';

export interface UseConnectSolanaWalletProps {
  setWalletPickerOpen: (open: boolean) => void;
  isWalletPickerOpen: boolean;

  /**
   * Allow us to display the ledger checkbox on our patched wallet adapter modal library
   */
  setShouldShowLedgerCheckbox?: (shouldShow: boolean) => void;
}

export function useConnectSolanaWallet({
  isWalletPickerOpen,
  setWalletPickerOpen,
  setShouldShowLedgerCheckbox,
}: UseConnectSolanaWalletProps) {
  const {
    promise,
    setPromise,
    walletDisconnectPromise,
    setWalletDisconnectPromise,
  } = useConnectSolanaWalletStore();
  const wallet = useWallet();
  const { publicKey, connected, connecting, disconnect } = wallet;

  const handleConnect = useCallback(
    async function handleConnectSolanaWallet() {
      disconnect();
      await new Promise<void>((resolve, reject) => {
        setWalletDisconnectPromise({ resolve, reject });
      });

      if (setShouldShowLedgerCheckbox) {
        setShouldShowLedgerCheckbox(true);
      }
      setWalletPickerOpen(true);

      return new Promise<WalletContextState>((resolve, reject) => {
        setPromise({ resolve, reject });
      });
    },
    [
      disconnect,
      setWalletDisconnectPromise,
      setWalletPickerOpen,
      setPromise,
      setShouldShowLedgerCheckbox,
    ],
  );

  useEffect(
    function resolveDisconnect() {
      if (!walletDisconnectPromise) return;

      if (!publicKey) {
        walletDisconnectPromise.resolve();
        setWalletDisconnectPromise(null);
      }
    },
    [publicKey, walletDisconnectPromise, setWalletDisconnectPromise],
  );

  useEffect(
    function resolveConnect() {
      if (!promise) return;

      if (connected && publicKey) {
        setWalletPickerOpen(false);
        promise.resolve(wallet);
        return setPromise(null);
      }
      const isUserStillConnecting = connecting || isWalletPickerOpen;
      if (isUserStillConnecting) return;

      promise.reject(new Error('User rejected the connection'));
      return setPromise(null);
    },
    [
      connected,
      connecting,
      isWalletPickerOpen,
      promise,
      publicKey,
      wallet,
      setPromise,
      setWalletPickerOpen,
    ],
  );

  return handleConnect;
}

interface ConnectSolanaWalletPromiseInfo {
  resolve: (value: WalletContextState) => void;
  reject: (error?: Error) => void;
}

interface DisconnectSolanaWalletPromiseInfo {
  resolve: () => void;
  reject: (error?: Error) => void;
}

interface ConnectSolanaWalletState {
  promise: ConnectSolanaWalletPromiseInfo | null;
  setPromise: (promise: ConnectSolanaWalletPromiseInfo | null) => void;

  walletDisconnectPromise: DisconnectSolanaWalletPromiseInfo | null;
  setWalletDisconnectPromise: (
    promise: DisconnectSolanaWalletPromiseInfo | null,
  ) => void;
}

const useConnectSolanaWalletStore = create<ConnectSolanaWalletState>()(
  (set) => ({
    promise: null,
    setPromise: (promise) => set({ promise }),

    walletDisconnectPromise: null,
    setWalletDisconnectPromise: (promise) =>
      set({ walletDisconnectPromise: promise }),
  }),
);
