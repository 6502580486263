import { showToast } from '@spherelabs/react-private/components/tailwind/AppToast';
import { nanoid } from 'nanoid';

export const showApprovalToast = (
  isSigningTransaction?: boolean,
): string | number => {
  return showToast({
    text: isSigningTransaction
      ? 'A transaction approval is necessary to verify your ownership of the wallet. Please note that no funds will be transferred, and the transaction will not be recorded on the blockchain.'
      : 'Heads up! Approval dialogs may appear below the main window. If you see a loading spinner, try minimizing the window to check for prompts. Cheers!',
    mode: 'warning',
    title: 'Just to let you know',
    duration: Number.POSITIVE_INFINITY, // disable auto close
    id: nanoid(),
  });
};
