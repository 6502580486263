import { AccessRequestSignInProvider } from '@spherelabs/common';
import type { useRouter } from 'next/navigation';
import {
  parseAsBoolean,
  parseAsString,
  parseAsStringEnum,
  useQueryStates,
} from 'nuqs';
import { useCallback } from 'react';

export function useAuthQueryParams() {
  const [params, setParams] = useQueryStates({
    providerId: parseAsStringEnum(
      Object.values(AccessRequestSignInProvider),
    ).withDefault(AccessRequestSignInProvider.EMAIL_AND_PASSWORD),
    prefill: parseAsBoolean.withDefault(false),
    walletAddress: parseAsString,
    accessToken: parseAsString,
    utm_source: parseAsString,
    utm_medium: parseAsString,
    utm_campaign: parseAsString,
  });

  const preserveParams = useCallback(
    (href: Parameters<ReturnType<typeof useRouter>['replace']>[0]) => {
      const url = new URL(href, window.location.origin);
      const searchParams = new URLSearchParams(url.search);
      for (const [key, value] of Object.entries(params)) {
        if (value === null) continue;
        searchParams.set(key, `${value}`);
      }
      return `${url.pathname}${
        searchParams.size > 0 ? `?${searchParams.toString()}` : ''
      }`;
    },
    [params],
  );

  return [params, setParams, { preserveParams }] as const;
}
