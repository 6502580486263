export const getSolanaMessageToSign = ({
  solanaPubKey,
  nonce,
  issuedAt,
}: {
  solanaPubKey: string;
  nonce: string;
  issuedAt: string;
}) => `spherepay.co wants you to sign in with your Solana account:
${solanaPubKey}

Clicking "Sign" or "Approve" only means you have proved this wallet is owned by you.
This request will not trigger any blockchain transactions or generate a fee.

URI: https://spherepay.co
Version: 1
Chain ID: mainnet
Nonce: ${nonce}
Issued At: ${issuedAt}`;

export const getEvmMessageToSign = ({
  address,
  nonce,
  issuedAt,
  chainId,
  chainName,
}: {
  address: string;
  nonce: string;
  issuedAt: string;
  chainId: number;
  chainName: string;
}) => {
  return `spherepay.co wants you to sign in with your ${chainName} account:
${address}

Clicking "Sign" or "Approve" only means you have proved this wallet is owned by you.
This request will not trigger any blockchain transactions or generate a fee.

URI: https://spherepay.co
Version: 1
Chain ID: ${chainId}
Nonce: ${nonce}
Issued At: ${issuedAt}`;
};
