import { motion } from 'framer-motion';

export function AuthFooter() {
  return (
    <motion.div
      layout
      className='flex justify-center sm:justify-start gap-6 sm:gap-8 text-sm text-[#7387a4] px-4 py-8 sm:py-16 sm:px-24'
    >
      <motion.span layout>© Sphere {new Date().getFullYear()}</motion.span>
      <motion.a
        layout
        href='/privacy-policy'
        className='text-center hover:underline hover:underline-offset-2 transition-all'
      >
        Privacy Policy
      </motion.a>
      <motion.a
        layout
        href='/terms-of-service'
        className='text-right hover:underline hover:underline-offset-2 transition-all'
      >
        Terms of Service
      </motion.a>
    </motion.div>
  );
}
