'use client';

import {
  SphereButton,
  SphereInput,
  SphereInputLabel,
  Typography,
} from '@spherelabs/react-private';
import { SphereInputError } from '@spherelabs/react-private/components/css/SphereInputError';
import { showToast } from '@spherelabs/react-private/components/tailwind/AppToast';
import {
  GoogleAuthProvider,
  getAuth,
  linkWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { useFormik } from 'formik';
import { EyeIcon, EyeOffIcon } from 'lucide-react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics, useFirebaseApp } from 'reactfire';

import yup from '@/common/lib/yup';
import { analytics } from '@/common/utils/analytics';

import { REDIRECT_QUERY_PARAM } from '../constants';
import { loginWithProvider } from '../utils/logInWithProvider';
import { AuthButton } from './AuthButton';
import { AuthFooter } from './AuthFooter';
import { AuthHeader } from './AuthHeader';
import { WalletAuthButton } from './WalletAuthButton';

import Google from '../../../../public/landing-page/Pages/Login/google.svg';

const SphereLogoWithShadow = dynamic(
  () => import('@/common/components/SphereLogoWithShadow'),
  { ssr: false },
);

// schemas
const validationSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Required'),
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters long')
    .required('Required'),
});

export default function LoginPage() {
  const [googleLoading, setGoogleLoading] = useState(false);
  const app = useFirebaseApp();
  const firebaseAnalytics = useAnalytics();
  const { i18n } = useTranslation();
  const searchParams = useSearchParams();
  const redirectQueryParam = searchParams?.get(REDIRECT_QUERY_PARAM);

  const hasShownLoginSnackbar = useRef(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const auth = getAuth(app);
        const userCredentials = await signInWithEmailAndPassword(
          auth,
          values.email,
          values.password,
        );

        const idToken = await userCredentials.user.getIdToken();
        const customToken = (await loginWithProvider(idToken))
          .firebaseCustomToken;
        const userCredential = await signInWithCustomToken(auth, customToken);

        if (userCredential.user?.uid) {
          analytics.registerUser(firebaseAnalytics, userCredential.user);
        }
      } catch (error) {
        if (error instanceof Error) {
          showToast({ text: error.message, mode: 'error' });
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(
    function showSnackbarWhenRedirectedToLogin() {
      if (
        !hasShownLoginSnackbar.current &&
        typeof redirectQueryParam === 'string'
      ) {
        showToast({ text: 'Please login before proceeding', mode: 'default' });
        hasShownLoginSnackbar.current = true;
      }
    },
    [redirectQueryParam],
  );

  // Google Login
  const onGoogleLoginClick = async () => {
    try {
      const auth = getAuth(app);
      const userCredentials = await signInWithPopup(
        auth,
        new GoogleAuthProvider(),
      );

      setGoogleLoading(true);

      const idToken = await userCredentials.user.getIdToken();
      const customToken = (await loginWithProvider(idToken))
        .firebaseCustomToken;
      let userCredential = await signInWithCustomToken(auth, customToken);

      const credential =
        GoogleAuthProvider.credentialFromResult(userCredentials);
      if (
        credential &&
        !userCredential.user.providerData.some(
          (userInfo) => userInfo.providerId === credential.providerId,
        )
      ) {
        userCredential = await linkWithCredential(
          userCredential.user,
          credential,
        );
      }

      if (userCredential?.user?.uid && userCredential.user.emailVerified) {
        analytics.registerUser(firebaseAnalytics, userCredential.user);
        showToast({
          text: `Login success! Welcome back ${userCredential.user.email}`,
          mode: 'success',
        });
      }
      setGoogleLoading(false);
    } catch (e) {
      if (e instanceof Error) {
        showToast({ text: e.message, mode: 'error' });
      }
    } finally {
      setGoogleLoading(false);
    }
  };

  return (
    <div className='bg-[#f5f7f9] min-h-screen w-full pt-[59px] md:pt-[69px]'>
      <AuthHeader />

      <div className='mx-auto w-full max-w-[28rem] lg:max-w-[32rem] px-4 py-[3.5rem]'>
        <div className='flex h-full flex-col max-w-md m-auto gap-2'>
          <form className='flex flex-col gap-6' onSubmit={formik.handleSubmit}>
            <Typography className='text-[#0F2A43] !font-bold !text-2xl'>
              Login to your account
            </Typography>

            <div className='flex flex-col gap-2'>
              <SphereInputLabel className='!text-sm'>Email</SphereInputLabel>
              <SphereInput
                type='email'
                name='email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className='bg-white'
                required
              />
              {formik.touched.email && formik.errors.email ? (
                <SphereInputError>{formik.errors.email}</SphereInputError>
              ) : null}
            </div>

            <div className='flex flex-col gap-2'>
              <SphereInputLabel className='!text-sm'>Password</SphereInputLabel>
              <SphereInput
                type={passwordVisible ? 'text' : 'password'}
                name='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                endAdornment={
                  <button
                    type='button'
                    onClick={() => setPasswordVisible((prev) => !prev)}
                    className='focus:outline-none flex items-center justify-center pr-1'
                    aria-label={
                      passwordVisible ? 'Hide password' : 'Show password'
                    }
                  >
                    {passwordVisible ? (
                      <EyeOffIcon className='h-5 w-5 text-[#d1d7e3]' />
                    ) : (
                      <EyeIcon className='h-5 w-5 text-[#d1d7e3]' />
                    )}
                  </button>
                }
                className='bg-white'
                required
              />
              {formik.touched.password && formik.errors.password ? (
                <SphereInputError>{formik.errors.password}</SphereInputError>
              ) : null}
              <Link
                href='/forgot-password'
                className='text-xs text-[#2C61F9] hover:text-[#3b82f6]'
              >
                Forgot Password
              </Link>
            </div>

            <SphereButton
              variant='contained'
              type='submit'
              onClick={formik.handleSubmit}
              loading={formik.isSubmitting}
              aria-label='Login'
            >
              Login
            </SphereButton>
          </form>

          <div className='flex items-center justify-center py-2'>
            <div className='flex-grow border-t border-gray-[#E4EBFF]' />
            <span className='flex-shrink mx-4 text-xs text-gray-[#7387A4]'>
              OR
            </span>
            <div className='flex-grow border-t border-gray-[#E4EBFF]' />
          </div>

          <div className='flex w-full flex-col items-center gap-2'>
            <div className='flex flex-col w-full gap-2'>
              <AuthButton
                primaryText='Sign in with Google'
                logos={[{ id: 'google', component: <Google /> }]}
                isLoading={googleLoading}
                onClick={onGoogleLoginClick}
              />
              <WalletAuthButton />
            </div>
          </div>

          <div className='flex items-center justify-center text-xs gap-1 mt-4'>
            <span className='text-[#98A2B3]'>Don&apos;t have an account?</span>
            <Link
              href={`/${i18n.language ?? 'en'}/access-request`}
              className='text-[#2C61F9] hover:text-[#3b82f6]'
            >
              Fill out an application
            </Link>
          </div>
        </div>
      </div>

      <AuthFooter />
    </div>
  );
}
