import { useWallet } from '@solana/wallet-adapter-react';
import { useTokensInfos } from '@spherelabs/react-private';
import { useDisconnect } from '@spherelabs/react-private/wagmi';
import { useEffect, useMemo, useState } from 'react';

import { useSignInWithEvm } from '../hooks/useSignInWithEvm';
import { useSignInWithSolana } from '../hooks/useSignInWithSolana';
import { AuthButton } from './AuthButton';

import Metamask from '../../../../public/landing-page/Pages/Login/metamask.svg';
import Phantom from '../../../../public/landing-page/Pages/Login/phantom.svg';
import WalletConnect from '../../../../public/landing-page/Pages/Login/walletconnect.svg';
import { NetworkSelectorDialog } from '@spherelabs/react-private/components/tailwind/NetworkSelector/NetworkSelectorDialog';
import { isEvm, isTestnet, Network } from '@spherelabs/common';

export const WalletAuthButton = () => {
  const { disconnect: disconnectSolanaWallet } = useWallet();
  const { disconnect: disconnectEvmWallet } = useDisconnect();
  const [hasDisconnectedAllWallets, setHasDisconnectedAllWallets] =
    useState(false);

  useEffect(
    function disconnectAllWalletsOnMount() {
      let hasUnmounted = false;
      (async () => {
        await Promise.allSettled([disconnectSolanaWallet, disconnectEvmWallet]);

        if (hasUnmounted) {
          return;
        }

        setHasDisconnectedAllWallets(true);
      })();

      return () => {
        hasUnmounted = true;
        setHasDisconnectedAllWallets(false);
      };
    },
    [disconnectEvmWallet, disconnectSolanaWallet],
  );

  if (!hasDisconnectedAllWallets) {
    return (
      <AuthButton
        primaryText='Connect via wallet'
        logos={[]}
        onClick={() => {}}
        isLoading
      />
    );
  }

  // only attempt to allow login via wallet after a single attempt to disconnect all wallets
  // this is to ensure that users only have one wallet connected at a time
  return <WalletAuthButtonInner />;
};

const WalletAuthButtonInner = () => {
  const [walletLoading, setWalletLoading] = useState(false);

  const [isChoosingNetwork, setIsChoosingNetwork] = useState(false);
  const tokens = useTokensInfos();
  const evmNetworks = useMemo(
    () =>
      Array.from(
        new Set(
          tokens
            ?.filter(
              (token) => isEvm(token.network) && !isTestnet(token.network),
            )
            .map((token) => token.network as Network),
        ),
      ),
    [tokens],
  );
  const supportedNetworks = useMemo(
    () => [Network.SOL, ...evmNetworks],
    [evmNetworks],
  );

  const signInWithSolana = useSignInWithSolana({
    setLoading: setWalletLoading,
  });
  const signInWithEvm = useSignInWithEvm({
    setLoading: setWalletLoading,
  });

  return (
    <>
      <AuthButton
        primaryText='Connect via wallet'
        secondaryText='(legacy users)'
        logos={[
          { id: 'phantom', component: <Phantom /> },
          { id: 'wallet-connect', component: <WalletConnect /> },
          { id: 'metamask', component: <Metamask /> },
        ]}
        isLoading={walletLoading}
        onClick={() => {
          setIsChoosingNetwork(true);
        }}
      />
      <NetworkSelectorDialog
        open={isChoosingNetwork}
        supportedNetworks={supportedNetworks}
        onClose={() => {
          setIsChoosingNetwork(false);
        }}
        onChange={(network) => {
          if (isEvm(network)) {
            signInWithEvm(network);
          } else {
            signInWithSolana();
          }
        }}
      />
    </>
  );
};
